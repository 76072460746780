import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract.js";
export const base_url = '/certificado';
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class CadastroPublic {

    static certificado(model) {
        return axios({
            url: url,
            data: model,
            method: "POST"
        });
    }

    static download(id) {
        return axios({
            url: url + '/download/' + id,
            method: "POST",
            responseType: "blob"
        });
    }

}