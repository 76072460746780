<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-12">
      <div class="card bg-secondary shadow border-0">

        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" @submit.prevent="submitForm()">

            <div class="row">
              <div class="col-12">
                <base-input label="CPF" required input-classes="form-control-alternative" placeholder="Digite seu CPF"
                  v-model="cpf" :errors="errors.cpf" v-mask="'###.###.###-##'">
                </base-input>
              </div>
              <!-- <div class="col-12 col-md-6">
                <base-input label="Nome" required input-classes="form-control-alternative" placeholder="Nome"
                  v-model="cpf" :errors="errors.cpf">
                </base-input>
              </div> -->


            </div>
            <div class="row">
              <div class="col-12">
                <base-input :rules="{ required: { allowFalse: false } }" name="Privacy" Policy>
                  <base-checkbox v-model="boolean">
                    <span class="text-muted">Eu concordo com os
                      <a href="#!">Termos e condições</a></span>
                  </base-checkbox>
                </base-input>
              </div>
            </div>
            <div class="text-center">
              <base-button type="primary" native-type="submit" :disabled="loading || success" class="mb-4">Enviar
                <i class="fa fa-spinner fa-spin" v-if="loading"></i></base-button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import Service from "../../services/CertificadoPublic";
import { helper } from "../../helper.js";
import axios from "axios";
import FileSaver from 'file-saver';

export default {
  name: "register",
  components: {
    ValidationError,
    Password
  },
  mixins: [formMixin],
  data() {
    return {
      boolean: false,
      arquivo: null,
      cpf: null,
      errors: {},

      loading: false,
      success: false,
    };
  },
  methods: {

    submitForm() {
      if (!this.boolean) {
        this.$notify({
          type: "danger",
          message: "Você precisa aceitar os termos e condições.",
        });
        return;
      }
      this.loading = true;

      let recaptcha = this.$recaptcha();
      recaptcha
        .then((token) => {

          this.errors = {};

          let data = { 'cpf': this.cpf };
          data.token = token;

          let formData = helper.getFormDataTrue(data);

          if (this.arquivo)
            formData.append('arquivo', this.arquivo);

          Service.certificado(formData).then(response => {
            this.success = true;

            this.$notify({
              type: "success",
              message: "Download Iniciado",
            })

            console.log('id => ', response.data);
            this.downloadFile(response.data);
          }).catch(error => {
            if (error.response.data == "Captcha Inválido") {
              this.$notify({
                type: "danger",
                message: "Oops, ocorreu um erro com o ReCaptcha!",
              })
            } else {
              this.$notify({
                type: "danger",
                message: "Oops, ocorreu um erro!",
              })

              this.errors = error.response.data.errors;
            }

          }).finally(() => {
            this.loading = false;
          })
        });
    },

    downloadFile(id) {
      Service.download(id).then(arquivo => {
        FileSaver.saveAs(arquivo.data, "Certificado.pdf");
      }).catch(error => {
        this.$notify({
          type: "danger",
          message: "Oops, ocorreu um erro! " + error,
        })
      });
    }
  },
};
</script>