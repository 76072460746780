<template>
  <div class="row justify-content-center mt-3">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" @submit.prevent="handleSubmit()">
            <base-input
              alternative
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="email"
              name="Email"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.email" />

            <base-input
              alternative
              class="input-group-alternative"
              placeholder="Senha"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
              name="Password"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.password" />

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Manter Conectado</span>
            </base-checkbox>
            <div class="text-center">
              <base-button type="primary" native-type="submit" class="my-4"
                >Entrar</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/password/reset" class="text-primary"
            ><small>Esqueceu a senha?</small></router-link
          >
        </div>
        <!-- <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  name: "login",
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    email: null,
    password: null,
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      const user = {
    
            username: this.email,
            password: this.password,
            grant_type:'password',
            client_secret:'50kmnTpYXXvMmRIIgBtSCZnIcly9zmNIIWdtCZKe',
            client_id:2
       
      };

      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Invalid credentials!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
